<template>
  <div>
    <v-snackbar
      v-model="showNotice"
      :multi-line="multiLine"
      :right="true"
      :timeout="timeout"
    >
      {{ text }}
      <hr>
      <div v-html="explanation"></div>

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="showNotice = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "ApiError",

  props: {
    error: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    multiLine: true,
    showNotice: false,
    timeout: 5000,
    text: '',
    explanation: '',
  }),

  watch: {
    error: {
      handler(val) {
        this.showNotice = true
        this.text = ''
        this.explanation = ''

        switch (val.status) {
          case 422:
            this.handleError422(val)
            break;
          default:
          this.handleError()
        }
      },
    },
  },

  methods:  {
    handleError422(errorObject) {
      this.text = errorObject.data.message
      let errorText = ''

      Object.keys(errorObject.data.errors).forEach(function(key) {
        errorObject.data.errors[key].forEach(function (errorMsg) {
            errorText += errorMsg + "<br>"
        })

      })
      
      this.explanation = errorText;
    },
    handleError() {
      this.text = "There was an error on the server!"
    }
  }
};
</script>
